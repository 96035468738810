import React from "react"
import { langVersion } from "../../accessors"

const FestFooterSocials = ({ data }) => {
  const { hrefUrl, title, imgUrl, name } = data
  return (
    <a
      className="fest__socials-link"
      href={hrefUrl}
      title={langVersion(title[0], title[1])}
      target="_blank"
    >
      <img className="fest__socials" src={imgUrl} alt={name}></img>
    </a>
  )
}

export default FestFooterSocials
