import React from "react";

import "./HeaderButtons.scss"

const HamburgerBtn = () => {

  return (
    <>
      <div className="hamburger__elem1" />
      <div className="hamburger__elem2" />
      <div className="hamburger__elem3" />
    </>
  )
}


export default HamburgerBtn