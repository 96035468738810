import React from "react"
import { Link } from "gatsby"

import "./HeaderButtons.scss"

const HeaderLanguageBtn = ({ link, lang }) => {
  //console.log(props.lang)
  let active = "language__link language__link--active";
  let nonActive = "language__link";


  return (
  <div className="language">{
    lang === 'Pl' ?
    (
      <Link className={lang === 'Eng' ? active : nonActive} title="Polish version" to={link}>
        <button className="button__language">Pl</button>
      </Link>
    ) :
    (
      <div className={lang === 'Eng' ? active : nonActive} title="Polish version">
        <button className="button__language">Pl</button>
      </div>
    ) 
  }{
    lang === 'Eng' ?
    (
    <Link className={lang === 'Pl' ? active : nonActive} title="English version" to={link}>
      <button className="button__language">En</button>
    </Link>
    ) :
    (
      <div className={lang === 'Pl' ? active : nonActive} title="English version">
      <button className="button__language">En</button>
    </div>
    )
  }
  </div>
)}

export default HeaderLanguageBtn