import React from "react"
import { langVersion } from "../../accessors";
import "./ComingSoon.scss";

const ComingSoon = () => {

  return <section className="service">
      <h2 className="service__title">
        {langVersion("Treść w trakcie opracowania","Content is coming soon")}
      </h2>
      <p className="service__text">
        {langVersion("Niedługo skończymy...","We are nearly complete...")}
      </p>
      <div className="service__image">
        <img className="gear-img" src="/images/icons/gear.svg"></img>
        <img className="gear-img" src="/images/icons/gear.svg"></img>
      </div>
  </section>
}

export default ComingSoon