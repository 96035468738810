import React, { useState } from "react"
import { isBrowser, langVersion } from "../../accessors"

import "./FestButtons.scss"

const KosmoBackTopBtn = () => {
  const [visible, onVisible] = useState(true)

  const handleClick = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const addListener = () => {
    isBrowser() &&
      window.addEventListener("scroll", () => handleScroll(visible))
  }

  const handleScroll = visibility => {
    window.scrollY > 500 && visibility === true && onVisible(false)
    window.scrollY <= 500 && visibility === false && onVisible(true)
  }

  addListener()

  return (
    <>
      <button
        onClick={handleClick}
        className={
          "fest__button-back-top" +
          (visible ? "" : " fest__button-back-top--active")
        }
        title={langVersion("Wróć do góry strony", "Back to top of the page")}
      >
        {langVersion("Góra", "Top")}
        <div className="fest__elem-move">
          <div className="fest__elem1"></div>
          <div className="fest__elem2"></div>
          <div className="fest__elem3"></div>
        </div>
      </button>
    </>
  )
}

export default KosmoBackTopBtn
